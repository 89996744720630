import React, { ChangeEvent, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import ProductCard from "../../components/product-card/ProductCard";
import { useGlobalContext } from "../../context/useGlobalContext";
import {
  IdentifiedBundleProduct,
  IdentifiedPluginProduct,
  BundleProductsGet,
  PluginProductsGet,
} from "../../services/products";

// Group bundles by category
const groupBundles = (
  bundles: IdentifiedBundleProduct[],
): Record<string, IdentifiedBundleProduct[]> => {
  return bundles.reduce(
    (acc, bundle) => {
      if (!acc["Bundles"]) {
        acc["Bundles"] = [];
      }
      acc["Bundles"].push(bundle);
      return acc;
    },
    {} as Record<string, IdentifiedBundleProduct[]>,
  );
};

// Group plugins by category
const groupPlugins = (
  plugins: IdentifiedPluginProduct[],
): Record<string, IdentifiedPluginProduct[]> => {
  return plugins.reduce(
    (acc, plugin) => {
      plugin.categories.forEach((category) => {
        if (!category.name) return;
        if (!acc[category.name]) {
          acc[category.name] = [];
        }
        acc[category.name].push(plugin);
      });
      return acc;
    },
    {} as Record<string, IdentifiedPluginProduct[]>,
  );
};

const Store: React.FC = () => {
  const { bundles, plugins } = useGlobalContext();

  const [searchQuery, setSearchQuery] = useState<string>("");

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const groupedBundles = groupBundles(bundles);
  const groupedPlugins = groupPlugins(plugins);
  const matchesSearchQuery = (str: String) =>
    str.toLowerCase().includes(searchQuery);

  const filteredBundles = Object.entries(groupedBundles).reduce(
    (acc: Record<string, IdentifiedBundleProduct[]>, [category, bundles]) => {
      const filtered = bundles.filter((bundle) => {
        const isNotAllPublicPlugins =
          searchQuery.trim() !== "" ? bundle.id !== "all-public-plugins" : true;
        const matchesName = matchesSearchQuery(bundle.name);
        const matchesCategory = matchesSearchQuery(category);
        const matchesPluginId = bundle.pluginIds.some(matchesSearchQuery);

        return (
          isNotAllPublicPlugins &&
          (matchesName || matchesCategory || matchesPluginId)
        );
      });
      if (filtered.length > 0) {
        acc["Bundles"] = filtered;
      }
      return acc;
    },
    {},
  );

  const filteredPlugins = Object.entries(groupedPlugins).reduce(
    (acc: Record<string, IdentifiedPluginProduct[]>, [category, plugins]) => {
      const filtered = plugins.filter(
        (plugin) =>
          plugin.name.toLowerCase().includes(searchQuery) ||
          category.toLowerCase().includes(searchQuery),
      );
      if (filtered.length > 0) {
        acc[category] = filtered;
      }
      return acc;
    },
    {},
  );

  const swiperComponent = (
    title: string,
    bundlesList?: BundleProductsGet,
    pluginsList?: PluginProductsGet,
  ) => {
    return (
      <div className="mb-8 2xl:mx-auto 2xl:w-5/6">
        <h2 className="mb-5 text-left text-3xl text-white md:text-4xl">
          {title}
        </h2>
        <Swiper
          spaceBetween={10}
          slidesPerView={4}
          navigation
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            640: {
              slidesPerView: 2,
            },
            1280: {
              slidesPerView: 3,
            },
            1800: {
              slidesPerView: 4,
            },
          }}
          modules={[Navigation]}
          className="py-5"
        >
          {bundlesList &&
            bundlesList.length > 0 &&
            bundlesList
              .sort((a, b) => b.price.amount - a.price.amount)
              .map((bundle, index) => (
                <SwiperSlide key={index}>
                  <div className="flex justify-center">
                    <div className="px-2"></div>
                    <ProductCard
                      product={bundle}
                      id={bundle.id}
                      img={bundle.imageUrl}
                      name={bundle.name}
                      version={`${bundle.pluginIds.length} Plugins`}
                      price={bundle.price.amount}
                      category={title === "Bundles" ? "Bundle" : ""}
                    />
                    <div className="px-2"></div>
                  </div>
                </SwiperSlide>
              ))}

          {pluginsList &&
            pluginsList.length > 0 &&
            pluginsList.map((plugin, index) => (
              <SwiperSlide key={index}>
                <div className="flex justify-center">
                  <div className="px-2"></div>
                  <ProductCard
                    product={plugin}
                    id={plugin.id}
                    img={
                      plugin.price.amount > 0
                        ? plugin.imageUrl
                        : `/assets/${plugin.id}/thumbnail.jpg`
                    }
                    name={plugin.name}
                    version={plugin.version}
                    price={plugin.price.amount}
                    categories={plugin.categories}
                  />
                  <div className="px-2"></div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    );
  };
  return (
    <div className="flex min-h-screen flex-col overflow-hidden">
      <Header />
      <section className="flex-grow px-5 pt-10 xl:pt-40">
        <div className="md:mx-20 xl:mx-40 2xl:mx-auto 2xl:w-5/6">
          <div className="mb-10 flex flex-col items-center 2xl:mx-auto 2xl:w-5/6">
            <h1
              className="bg-gradient-to-r from-purple-400 via-purple-500 to-blue-600 bg-clip-text pt-16 text-center text-4xl font-bold leading-[105px] text-transparent md:mb-6 md:text-6xl"
              data-aos="zoom-y-out"
            >
              Plugin Shop
            </h1>
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search for a plugin or a category..."
              className="mt-8 w-full max-w-md rounded-full bg-gray-800 px-4 py-2 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
          </div>
          {Object.entries(filteredBundles).map(([category, bundles], index) => (
            <div key={index}>{swiperComponent(category, bundles, [])}</div>
          ))}

          {Object.entries(filteredPlugins).map(([category, plugins], index) => (
            <div key={index}>{swiperComponent(category, [], plugins)}</div>
          ))}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Store;
