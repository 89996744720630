import React, { memo } from "react";
import { getDiscordToken } from "../../utils";
import DiscordLogoFilled from "../../assets/DiscordLogoFilled";

const DiscordAuthButton = memo(function DiscordAuthButton() {
  return (
    <div>
      <button
        className="flex w-full transform items-center gap-x-2 rounded-lg bg-[#36393e] px-4 py-2 font-semibold text-white shadow-lg transition-all duration-200 hover:scale-105 hover:bg-[#7289da] active:scale-95 sm:w-auto md:px-6"
        onClick={getDiscordToken}
      >
        <span className="flex items-center">
          Connect with
          <DiscordLogoFilled className="ml-2 h-[22px] w-[22px]" />
        </span>
      </button>
    </div>
  );
});

export default DiscordAuthButton;
