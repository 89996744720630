import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useCartContext } from "../../context/CartContext";
import KuriLogo from "../../assets/kuri-logo.png";
import Cart from "../../assets/Cart";
import useSellixBundlesAndPlugins from "../../hooks/useProductBundlesAndPlugins";
import MobileMenu from "../mobile-menu/MobileMenu";
import NavigationItem from "../navigation-item/NavigationItem";

const Header: React.FC = () => {
  const [top, setTop] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  const { cart, toggleDrawer } = useCartContext();
  const cartItemCount = cart.length;

  // Fetches plugins and bundles
  const { progress, error } = useSellixBundlesAndPlugins();

  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.scrollY > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  useEffect(() => {
    if (progress >= 100) {
      const fadeOutTimeOut = setTimeout(() => {
        setFadeOut(true);
      }, 500);

      const visibleTimeOut = setTimeout(() => {
        setIsVisible(false);
      }, 1000);
      return () => {
        clearTimeout(fadeOutTimeOut);
        clearTimeout(visibleTimeOut);
      };
    }
  }, [progress]);

  return (
    <header
      className={`fixed z-30 w-full border-b-0 transition duration-300 ease-in-out md:bg-opacity-90 ${
        !top && "bg-background-100 shadow-lg backdrop-blur-sm"
      }`}
    >
      <div className="px-5 sm:px-6 md:mx-20 xl:mx-40">
        <div className="flex h-16 items-center justify-start md:h-20">
          {/* Site branding */}
          <div className="z-30 mr-4 flex-shrink-0">
            {/* Logo */}
            <Link to="/" className="block">
              <img src={KuriLogo} className="h-8 w-8" alt="Kuri" />
            </Link>
          </div>

          {/* Desktop Site navigation */}
          <nav className="hidden md:flex md:grow">
            <ul className="flex grow flex-wrap items-center justify-center">
              <NavigationItem to="/">Home</NavigationItem>
              <NavigationItem to="/store">Store</NavigationItem>
              <NavigationItem to="/getting-started">
                Getting Started
              </NavigationItem>
              <NavigationItem to="/faq">FAQ</NavigationItem>
              <NavigationItem to="/about">About</NavigationItem>
            </ul>
            <ul className="flex flex-wrap items-center justify-end">
              <li>
                <button
                  onClick={toggleDrawer}
                  className="btn-sm btn-gradient relative ml-3 text-white transition duration-150 ease-in-out hover:bg-white"
                  aria-label="Checkout with items in cart"
                >
                  <Cart />
                  <span className="mx-2">Checkout</span>
                  {cartItemCount > 0 && (
                    <span
                      className="absolute bottom-0 right-0 flex translate-x-1/2 translate-y-1/2 transform items-center justify-center rounded-full bg-red-500 text-sm text-white"
                      style={{ width: "30px", height: "30px" }}
                    >
                      {cartItemCount}
                    </span>
                  )}
                </button>
              </li>
            </ul>
          </nav>

          <MobileMenu />
        </div>
      </div>

      {isVisible && (
        <div
          className={`absolute bottom-0 left-0 h-1 w-full bg-transparent transition-opacity duration-1000 ${
            fadeOut ? "opacity-0" : "opacity-100"
          }`}
        >
          <div
            className={`h-full transition-all ${
              error ? "duration-500" : "duration-1000"
            }`}
            style={{
              width: `${progress}%`,
              background: error
                ? "red"
                : "linear-gradient(to right, #9f7aea, #805ad5, #4299e1)",
            }}
          />
        </div>
      )}
    </header>
  );
};

export default Header;
