import React from 'react';

interface ShoppingCartProps {
    className?: string;
  }

const ShoppingCart: React.FC<ShoppingCartProps> = ({className = ""}: ShoppingCartProps) => {
  return (
    <svg
      height="250px"
      width="250px"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 502 502"
      xmlSpace="preserve"
      className={className}
    >
      <g>
        <polygon
          style={{ fill: "#FFF9E6" }}
          points="416.188,321.474 156.721,321.474 197.4,146.36 492,146.36 	"
        />
        <circle
          style={{ fill: "#5CC9C1" }}
          cx="148.987"
          cy="383.404"
          r="24.559"
        />
        <circle
          style={{ fill: "#5CC9C1" }}
          cx="389.494"
          cy="383.404"
          r="24.559"
        />
        <g>
          <path
            d="M139.693,350.125c-14.554,4.07-25.265,17.443-25.265,33.279c0,19.056,15.503,34.559,34.559,34.559
        c16.35,0,30.074-11.417,33.645-26.694h173.217c3.571,15.278,17.295,26.694,33.645,26.694c19.056,0,34.559-15.503,34.559-34.559
        s-15.503-34.559-34.559-34.559c-14.786,0-27.428,9.337-32.353,22.423h-175.8c-3.63-9.645-11.453-17.244-21.238-20.578
        l4.508-19.217h251.578c3.987,0,7.593-2.368,9.177-6.027l75.812-175.114c1.338-3.091,1.03-6.647-0.818-9.463
        c-1.849-2.815-4.99-4.51-8.358-4.51H205.888l-7.164-43.511c-0.79-4.798-4.918-8.333-9.78-8.375l-50.185-0.435
        c-0.03,0-0.06,0-0.089,0c-5.482,0-9.95,4.42-9.998,9.914c-0.048,5.522,4.391,10.038,9.913,10.086l41.771,0.362l6.854,41.629
        L139.693,350.125z M389.494,368.846c8.027,0,14.559,6.531,14.559,14.559s-6.531,14.559-14.559,14.559s-14.559-6.531-14.559-14.559
        S381.467,368.846,389.494,368.846z M148.987,397.963c-8.027,0-14.559-6.531-14.559-14.559s6.531-14.559,14.559-14.559
        s14.559,6.531,14.559,14.559S157.015,397.963,148.987,397.963z M409.621,311.474H169.31l5.772-24.846H391.63
        c5.522,0,10-4.477,10-10s-4.478-10-10-10H179.728l5.276-22.711h140.424c5.522,0,10-4.477,10-10s-4.478-10-10-10H189.65
        l5.276-22.71h182.823c5.522,0,10-4.477,10-10s-4.478-10-10-10H199.572l5.772-24.846h271.43L409.621,311.474z"
          />
          <path
            d="M416.188,201.206h16.017c5.522,0,10-4.477,10-10s-4.478-10-10-10h-16.017c-5.522,0-10,4.477-10,10
        S410.666,201.206,416.188,201.206z"
          />
          <path d="M366.003,223.916c-5.522,0-10,4.477-10,10s4.478,10,10,10h19.221c5.522,0,10-4.477,10-10s-4.478-10-10-10H366.003z" />
          <path
            d="M134.251,298.386c0-5.523-4.478-10-10-10h-81.15c-5.522,0-10,4.477-10,10s4.478,10,10,10h81.15
        C129.773,308.386,134.251,303.909,134.251,298.386z"
          />
          <path
            d="M62.673,214.422h81.15c5.522,0,10-4.477,10-10s-4.478-10-10-10h-81.15c-5.522,0-10,4.477-10,10
        S57.15,214.422,62.673,214.422z"
          />
          <path
            d="M82.608,241.404c-5.522,0-10,4.477-10,10s4.478,10,10,10h8.542c5.522,0,10-4.477,10-10s-4.478-10-10-10L82.608,241.404
        L82.608,241.404z"
          />
          <path
            d="M52.033,251.404c0-5.523-4.478-10-10-10H10c-5.522,0-10,4.477-10,10s4.478,10,10,10h32.033
        C47.556,261.404,52.033,256.927,52.033,251.404z"
          />
        </g>
      </g>
    </svg>
  );
};

export default ShoppingCart;