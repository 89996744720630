import React, { createContext, useContext, useState, useEffect } from "react";
import {
  IdentifiedStripePrice,
  IdentifiedBundleProduct,
  IdentifiedPluginProduct,
} from "../services/products";

export interface CartItem {
  product: IdentifiedBundleProduct | IdentifiedPluginProduct;
  price: IdentifiedStripePrice;
}

interface CartContextProps {
  cart: CartItem[];
  addToCart: (
    product: IdentifiedBundleProduct | IdentifiedPluginProduct,
    price: IdentifiedStripePrice,
  ) => void;
  removeFromCart: (productId: string) => void;
  clearCart: () => void;
  drawerOpen: boolean;
  toggleDrawer: () => void;
}

const CartContext = createContext<CartContextProps | undefined>(undefined);

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [cart, setCart] = useState<CartItem[]>(() => {
    const savedCart = localStorage.getItem("cart");
    return savedCart ? JSON.parse(savedCart) : [];
  });

  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  const addToCart = (
    product: IdentifiedBundleProduct | IdentifiedPluginProduct,
    price: IdentifiedStripePrice,
  ) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find(
        (item) => item.product.id === product.id,
      );
      if (existingItem) {
        existingItem.price = price;
        return prevCart;
      }
      return [...prevCart, { product, price }];
    });
  };

  const removeFromCart = (productId: string) => {
    setCart((prevCart) =>
      prevCart.filter((item) => item.product.id !== productId),
    );
  };

  const clearCart = () => {
    setCart([]);
  }

  const toggleDrawer = () => {
    setDrawerOpen((prev) => !prev);
    if (!drawerOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  return (
    <CartContext.Provider
      value={{ cart, addToCart, removeFromCart, clearCart, drawerOpen, toggleDrawer }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCartContext = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCartContext must be used within a CartProvider");
  }
  return context;
};
