export const getDiscordToken = () => {
    const CLIENT_ID = process.env.REACT_APP_DISCORD_CLIENT_ID;
    const REDIRECT_URI =
        `${window.location.origin}${process.env.REACT_APP_DISCORD_REDIRECT_URI}` ||
        "";
    const SCOPE = "identify email guilds.join";

    const DISCORD_AUTH_URL = `https://discord.com/oauth2/authorize?client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(
        REDIRECT_URI,
    )}&response_type=token&scope=${encodeURIComponent(SCOPE)}`;

    window.open(DISCORD_AUTH_URL, "_blank", "width=800,height=600");
};