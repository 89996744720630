import React from "react";
import { Link } from "react-router-dom";
import pluginIcon from "../../assets/gearswapper-pannel-icon.png";
import Gif1 from "../../assets/gif1.gif";
import Gif2 from "../../assets/gif2.gif";
import GifPlayer from "../../components/gif-player/GifPlayer";
import { KuriPlugins } from "../../constants/data";
import DiscordLogoFilled from "../../assets/DiscordLogoFilled";

function HeroHome() {
  const hotPlugins = KuriPlugins.filter((e) => e.status === "HOT");
  const plugin = hotPlugins[0];

  // Duration in milliseconds (e.g., 5 seconds = 5000 milliseconds)
  const gif1Duration = 9000;
  const gif2Duration = 5000;
  return (
    <section className="relative w-full">
      <div className="px-5 sm:px-6 md:mx-20 xl:mx-40">
        {/* Hero content */}
        <div className="mx-auto pb-12 pt-10 md:pb-20 md:pt-40 2xl:w-5/6">
          {/* Section header */}
          <main className="hero-home-grid grid place-items-center pb-8 pt-16 md:pb-24 xl:gap-x-20">
            <div>
              <div
                className="text-center text-4xl font-bold leading-tight text-white md:text-left md:text-6xl lg:tracking-tight xl:tracking-tighter"
                data-aos="zoom-y-out"
              >
                Login, click, and chill to level up any skill using the best
                Runelite plugins
              </div>
              <p
                className="text-1xl my-7 text-center font-normal text-secondary-100 md:text-left md:text-2xl"
                data-aos="zoom-y-out"
                data-aos-delay="150"
              >
                Kuri Plugins focuses on providing overpowered, non-automated
                plugins that you can safely use on your main account.
              </p>
              <div
                className="md:flex"
                data-aos="zoom-y-out"
                data-aos-delay="300"
              >
                <Link
                  to="/store"
                  className="btn-gradient my-5 flex w-full items-center justify-center rounded-lg px-6 py-3 transition duration-150 ease-in-out hover:bg-white md:relative md:my-0 md:flex md:w-[218px] md:gap-2.5 md:rounded-2xl"
                >
                  <p className="flex-shrink-0 flex-grow-0 text-center text-2xl font-semibold text-white">
                    Store
                  </p>
                </Link>
                <div className="justify-end">
                  <Link
                    to="https://discord.gg/3bhXYtd8bt"
                    target="_blank"
                    className="flex w-full items-center justify-center gap-2.5 rounded-lg border border-secondary-200 px-6 py-3 text-secondary-200 hover:text-white md:relative md:ml-10 md:w-[218px] md:rounded-2xl"
                  >
                    <DiscordLogoFilled className="h-[27px] w-[27px]" />
                    <p className="flex-shrink-0 flex-grow-0 text-left text-2xl font-semibold">
                      Discord
                    </p>
                  </Link>
                </div>
              </div>
            </div>

            <div
              className="rounded-gradient-border relative mx-auto mt-20 items-end justify-self-end md:order-1 md:block lg:mt-0"
              data-aos="zoom-y-out"
              data-aos-delay="450"
            >
              <div className="box-background-faded rounded-[35px] px-4 py-4 md:px-6 md:py-7">
                <div className="absolute -left-12 -top-20 h-32 w-32 bg-fuchsia-500 blur-[90px]" />
                <GifPlayer gif1={Gif1} gif2={Gif2} gif1Duration={gif1Duration} gif2Duration={gif2Duration} />

                <div className="flex pt-4 md:pt-7">
                  <img src={pluginIcon} height="50px" width="50px" alt="" />
                  <div className="pl-3">
                    <p className="text-left text-base font-semibold text-white">
                      Kuri Gear Swapper
                    </p>
                    <p className="text-left text-base font-medium text-secondary-200">
                      PvM / PvP
                    </p>
                  </div>
                  <div className="flex-grow"></div>
                  <div className="flex-wrap justify-end">
                    <p className="text-right text-base font-semibold text-white">
                      HOT
                    </p>
                    <p className="text-left text-base font-medium text-secondary-200">
                      v1.6.22
                    </p>
                  </div>
                </div>
                <div className="absolute -bottom-16 -right-8 h-32 w-32 bg-cyan-500 blur-[90px]" />
              </div>
            </div>
          </main>
        </div>
      </div>
    </section>
  );
}

export default HeroHome;
