import Aos from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import About from "./pages/about/About";
import BundleDetails from "./pages/bundle-details/BundleDetails";
import GettingStarted from "./pages/getting-started/GettingStarted";
import Home from "./pages/home/Home";
import LoginSuccess from "./pages/login/LoginSuccess";
import LoginFailed from "./pages/login/LoginFailed";
import PluginDetails from "./pages/plugin-details/PluginDetails";
import Plugins from "./pages/plugins/Plugins";
import Privacy from "./pages/policies/Privacy";
import Terms from "./pages/policies/Terms";
import Callback from "./pages/register/Callback";
import Register from "./pages/register/Register";
import Store from "./pages/store/Store";
import FAQ from "./pages/faq/FAQ";
import { DropdownProvider } from "./context/DropdownContext";
import { CartProvider, useCartContext } from "./context/CartContext";
import Dropdown from "./components/dropdown/Dropdown";
import CheckoutDrawer from "./components/checkout/CheckoutDrawer";
import AuthSuccess from "./pages/discord-auth/AuthSuccess";

const App = () => {
  const navigate = useNavigate();

  const location = useLocation();
  useEffect(() => {
    Aos.init({
      once: true,
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  useEffect(() => {
    const htmlElement = document.querySelector("html");
    if (htmlElement) {
      htmlElement.style.scrollBehavior = "auto";
      window.scrollTo({ top: 0 });
      htmlElement.style.scrollBehavior = "";
    }
  }, [location.pathname]);

  const DiscordRedirect = () => {
    const { clearCart } = useCartContext();

    useEffect(() => {
      window.open(process.env.REACT_APP_DISCORD_URL, "_blank");
      clearCart();
      navigate("/");
    }, []);
    return "";
  };

  return (
    <CartProvider>
      <DropdownProvider>
        <Toaster position="top-right" containerClassName="mt-16" />
        <CheckoutDrawer />
        <Dropdown />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/register/callback" element={<Callback />} />
          <Route path="/plugins" element={<Plugins />} />
          <Route path="/plugin-details/:id" element={<PluginDetails />} />
          <Route path="/bundle-details/:id" element={<BundleDetails />} />
          <Route path="/store" element={<Store />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/terms-and-conditions" element={<Terms />} />
          <Route path="/getting-started" element={<GettingStarted />} />
          <Route path="/about" element={<About />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/login-success" element={<LoginSuccess />} />
          <Route path="/login-failed" element={<LoginFailed />} />
          <Route path="/discord-authenticated" element={<AuthSuccess />} />
          <Route path="/discord" element={<DiscordRedirect />} />
        </Routes>
      </DropdownProvider>
    </CartProvider>
  );
};

export default App;
