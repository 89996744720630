const AddToCart = () => (
    <svg fill="#ffffff" className="h-5 w-5" version="1.1" viewBox="0 0 380 380" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
      <g id="XMLID_915_">
        <path
          id="XMLID_916_"
          d="M290,146.077V55h25c8.284,0,15-6.716,15-15s-6.716-15-15-15h-40c-8.284,0-15,6.716-15,15v25H15 
          c-5.024,0-9.715,2.516-12.495,6.701c-2.78,4.186-3.279,9.484-1.331,14.116l58.896,140c2.341,5.564,7.79,9.184,13.826,9.184h97.18 
          c-0.704,4.9-1.076,9.907-1.076,14.999c0,5.092,0.372,10.099,1.076,15H46c-8.284,0-15,6.716-15,15c0,8.284,6.716,15,15,15h17.58 
          c-1.665,4.695-2.58,9.742-2.58,15c0,24.813,20.187,45,45,45s45-20.187,45-45c0-5.258-0.915-10.305-2.58-15h31.725 
          c16.883,35.443,53.053,60,94.855,60c57.898,0,105-47.103,105-105C380,197.195,340.816,153.377,290,146.077z M121,309.999 
          c0,8.271-6.729,15-15,15s-15-6.729-15-15s6.729-15,15-15S121,301.728,121,309.999z M83.859,205L37.583,95H260v51.076 
          c-35.328,5.076-65.029,27.798-79.855,58.924H83.859z M275,324.999c-41.355,0-75-33.645-75-75C200,208.645,233.644,175,275,175 
          s75,33.645,75,74.999C350,291.354,316.355,324.999,275,324.999z"
        />
        <path
          id="XMLID_921_"
          d="M305,234.999h-15V220c0-8.284-6.716-15-15-15c-8.284,0-15,6.716-15,15v14.999h-15c-8.284,0-15,6.716-15,15 
          s6.716,15,15,15h15v15c0,8.284,6.716,15,15,15c8.284,0,15-6.716,15-15v-15h15c8.284,0,15-6.716,15-15S313.284,234.999,305,234.999z"
        />
      </g>
    </svg>
  );
  
  export default AddToCart;