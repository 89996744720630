import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useGlobalContext } from "../context/useGlobalContext";
import { ProductService } from "../services/products";

const useSellixBundlesAndPlugins = () => {
  const { setBundles, setPlugins } = useGlobalContext(); // Use the context
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const location = useLocation(); // Get current path

  const allowedPaths = useMemo(() => ["/", "/plugins", "/store"], []);
  const dynamicPaths = useMemo(
    () => ["/plugin-details/", "/bundle-details/"],
    [],
  );

  const isAllowedPath = useMemo(() => {
    if (allowedPaths.includes(location.pathname)) {
      return true;
    }

    return dynamicPaths.some((path) => location.pathname.startsWith(path));
  }, [location.pathname, allowedPaths, dynamicPaths]);

  useEffect(() => {
    if (!isAllowedPath) {
      return;
    }

    const storedBundles = sessionStorage.getItem("bundles");
    const storedPlugins = sessionStorage.getItem("plugins");

    if (storedBundles && storedPlugins) {
      setBundles(JSON.parse(storedBundles));
      setPlugins(JSON.parse(storedPlugins));
      return;
    }

    let interval: NodeJS.Timeout;

    const fetchData = async () => {
      setLoading(true);
      setProgress(0);
      setError(false);

      const milestones = [25, 50, 75];
      let milestoneIndex = 0;

      const updateProgress = () => {
        if (milestoneIndex < milestones.length) {
          setProgress(milestones[milestoneIndex]);
          milestoneIndex++;
        } else {
          clearInterval(interval);
        }
      };

      interval = setInterval(updateProgress, 400); // Progress update interval

      try {
        const bundlesRes = storedBundles
          ? JSON.parse(storedBundles)
          : await ProductService.getBundleProducts();
        const pluginsRes = storedPlugins
          ? JSON.parse(storedPlugins)
          : await ProductService.getPluginProducts();

        // Set bundles and plugins, and cache in sessionStorage
        if (!storedBundles) {
          setBundles(bundlesRes);
          sessionStorage.setItem("bundles", JSON.stringify(bundlesRes));
        }

        if (!storedPlugins) {
          setPlugins(pluginsRes);
          sessionStorage.setItem("plugins", JSON.stringify(pluginsRes));
        }

        setProgress(100); // Set progress to complete
      } catch (error) {
        console.error("Error fetching Sellix data:", error);
        setError(true);
        setProgress(100);
      } finally {
        clearInterval(interval);
        setLoading(false);
      }
    };

    fetchData();

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [isAllowedPath, setBundles, setPlugins]);

  return { loading, progress, error };
};

export default useSellixBundlesAndPlugins;
