import { useImperativeHandle, useRef, useState } from "react";
import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import TermsModal from "./TermsModal";
import CloseIcon from "../../assets/close-icon.png";
import { useCartContext } from "../../context/CartContext";

const StripeEmbed = React.forwardRef(({ options }: any, ref) => {
  const { clearCart } = useCartContext();
  const [showCheckout, setShowCheckout] = useState(false);
  const modalRef = useRef<HTMLDialogElement>(null);
  const termsModalRef = useRef<any>(null);

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "");

  useImperativeHandle(ref, () => ({
    showModal() {
      setShowCheckout(true);
      modalRef.current?.showModal();
    },
    closeModal() {
      setShowCheckout(false);
      modalRef.current?.close();
    },
  }));

  const handleOnComplete = () => {
    clearCart();
  };

  const handleCloseModal = () => {
    setShowCheckout(false);
    modalRef.current?.close();
  };

  const handleShowTerms = (e: React.MouseEvent) => {
    e.stopPropagation();
    termsModalRef.current?.showModal();
  };

  return (
    <div id="checkout" className="my-4">
      <dialog
        ref={modalRef}
        className="no-scrollbar w-4/5 rounded-xl bg-black p-5 backdrop:bg-black/75"
      >
        {showCheckout && stripePromise && (
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{
              ...options,
              onComplete: handleOnComplete,
            }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
        <div className="mt-4 text-center">
          <p className="text-sm text-gray-500">
            <strong>
              By continuing with checkout you agree to KuriPlugins{" "}
              <button onClick={handleShowTerms} className="underline">
                Terms of Service
              </button>
            </strong>
          </p>
        </div>
        <img
          className="absolute right-5 top-5 h-5 cursor-pointer transition-all duration-200 hover:opacity-10"
          src={CloseIcon}
          alt="Close"
          onClick={handleCloseModal}
        />
      </dialog>

      <TermsModal ref={termsModalRef} />
    </div>
  );
});

export default StripeEmbed;
