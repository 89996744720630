import React, { forwardRef, useImperativeHandle, useRef } from "react";
import CloseIcon from "../../assets/close-icon.png";

const TermsModal = forwardRef((props, ref) => {
  const termsModalRef = useRef<HTMLDialogElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = (event: MouseEvent) => {
    const target = event.target as Node;
    const content = contentRef.current;

    if (content && !content.contains(target)) {
      termsModalRef.current?.close();
      document.removeEventListener("click", handleOutsideClick);
    }
  };

  useImperativeHandle(ref, () => ({
    showModal() {
      termsModalRef.current?.showModal();
      document.addEventListener("click", handleOutsideClick);
    },
    closeModal() {
      termsModalRef.current?.close();
      document.removeEventListener("click", handleOutsideClick);
    },
  }));

  return (
    <dialog
      ref={termsModalRef}
      className="rounded-xl bg-background-500 pb-5 backdrop:bg-black/75"
    >
      <div
        ref={contentRef}
        className="max-h-96 max-w-lg overflow-y-auto p-5 text-left text-sm text-white"
      >
        <h2 className="font-bold">
          KuriPlugins Subscriptions - Terms of Service
        </h2>
        <p>&nbsp;</p>
        <p className="italic">Last Updated: 2025-02-09</p>
        <p>&nbsp;</p>
        <h3 className="font-bold">1. Acceptance of Terms</h3>
        <p>&nbsp;</p>
        <p>
          By purchasing or subscribing to any plugin or bundle from{" "}
          <span className="font-bold">KuriPlugins</span> ("the Service"), you
          acknowledge that you have read, understood, and agree to be bound by
          these Terms of Service ("Terms"). If you do not agree to these Terms,
          you may not access or use the Service.
        </p>
        <p>&nbsp;</p>
        <h3 className="font-bold">2. Service Description</h3>
        <p>&nbsp;</p>
        <p>
          KuriPlugins offers premium plugins for the{" "}
          <a
            href="https://runelite.net/"
            target="_blank"
            rel="noreferrer"
            className="text-blue-500 underline"
          >
            RuneLite
          </a>{" "}
          platform ("Plugins"). Subscription tiers and durations provide access
          to selected plugins or bundles, as described on our website.
        </p>
        <p>&nbsp;</p>
        <h3 className="font-bold">3. Subscription Terms</h3>
        <p>&nbsp;</p>
        <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
          <li>
            <strong>Subscription Period</strong>: Subscriptions are offered for
            fixed durations (e.g., 1, 3, 6, or 12 months) and provide access to
            the selected plugin(s) or bundle(s) for the specified period.
          </li>
          <li>
            <strong>Auto-Renewal</strong>: Subscriptions renew automatically
            unless you disable auto-renewal before the renewal date. Renewal
            fees are charged using the payment method on file.
          </li>
          <li>
            <strong>Cancellation</strong>: You can cancel your subscription at
            any time through your stripe account settings on the customer portal
            or by contacting support (via email or Discord). Access to the
            purchased plugin(s) will remain active until the end of the current
            billing period. No refunds are issued for unused portions of a
            subscription unless required by applicable law.
          </li>
        </ul>
        <p>&nbsp;</p>
        <h3 className="font-bold">4. Pricing and Payment</h3>
        <p>&nbsp;</p>
        <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
          <li>
            <strong>Pricing</strong>: All prices are listed on our website and
            are subject to change. Pricing changes do not affect active
            subscriptions during their current term but may apply upon renewal.
          </li>
          <li>
            <strong>Payment Methods</strong>: Payments are securely processed
            through <strong>Stripe</strong>. By making a purchase, you agree to
            abide by Stripe's{" "}
            <a
              href="https://stripe.com/terms"
              target="_blank"
              rel="noreferrer"
              className="text-blue-500 underline"
            >
              terms of service
            </a>
            .
          </li>
          <li>
            <strong>Refunds</strong>: Refunds are only provided in exceptional
            cases, such as duplicate purchases or technical issues preventing
            plugin access, and are evaluated on a case-by-case basis. Refunds
            for digital goods are subject to applicable consumer protection
            laws.
          </li>
        </ul>
        <p>&nbsp;</p>
        <h3 className="font-bold">5. License and Usage</h3>
        <p>&nbsp;</p>
        <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
          <li>
            <strong>License Grant</strong>: Upon subscribing, you are granted a
            limited, non-exclusive, non-transferable, revocable license to use
            the purchased plugin(s) for personal, non-commercial purposes only.
          </li>
          <li>
            <strong>Prohibited Actions</strong>: Redistribution, credential
            sharing, resale, reverse engineering, or unauthorized modification
            of plugins is strictly prohibited. Violating these terms may result
            in immediate suspension or termination of your access without
            refund.
          </li>
        </ul>
        <p>&nbsp;</p>
        <h3 className="font-bold">6. Conduct and Suspension of Service</h3>
        <p>&nbsp;</p>
        <p>
          You agree to use the Service responsibly and refrain from actions that
          harm KuriPlugins, its reputation, or its users. Prohibited conduct
          includes, but is not limited to:
        </p>
        <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
          <li>Defamation or spreading false information about KuriPlugins.</li>
          <li>Threatening chargebacks or engaging in fraudulent behavior.</li>
          <li>Attempting to reverse engineer, hack, or exploit the Service.</li>
          <li>
            Harassment, abuse, or disruptive behavior toward staff or other
            users.
          </li>
        </ul>
        <p>
          We reserve the right to suspend or terminate your access to the
          Service at any time, without prior notice, if you breach these Terms
          or engage in harmful conduct. Suspensions or terminations under this
          clause are non-refundable.
        </p>
        <p>&nbsp;</p>
        <h3 className="font-bold">7. Account Responsibility</h3>
        <p>&nbsp;</p>
        <p>
          You are solely responsible for maintaining the confidentiality of your
          account credentials and for all activities that occur under your
          account. If you suspect unauthorized access, you must notify us
          immediately. KuriPlugins is not responsible for losses or damages
          resulting from account misuse.
        </p>
        <p>&nbsp;</p>
        <h3 className="font-bold">8. Intellectual Property</h3>
        <p>&nbsp;</p>
        <p>
          All plugins, content, and materials provided by KuriPlugins are
          protected by copyright, trademark, and other intellectual property
          laws. Purchasing a subscription grants limited access to use these
          materials but does not transfer ownership or grant any intellectual
          property rights.
        </p>
        <p>&nbsp;</p>
        <h3 className="font-bold">9. Modifications to Service</h3>
        <p>&nbsp;</p>
        <p>
          We reserve the right to modify, suspend, or discontinue any part of
          the Service at any time, including changes to features, pricing, or
          subscription offerings. If significant changes affect your
          subscription, we will notify you in advance.
        </p>
        <p>&nbsp;</p>
        <h3 className="font-bold">10. Termination of Service</h3>
        <p>&nbsp;</p>
        <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
          <li>
            <strong>By Us</strong>: We reserve the right to terminate or suspend
            your access to the Service for violations of these Terms, fraudulent
            activity, harmful conduct, or other misuse. No refunds will be
            issued in such cases.
          </li>
          <li>
            <strong>By You</strong>: You may terminate your subscription at any
            time. Access to the Service will end after the current subscription
            period expires.
          </li>
        </ul>
        <p>&nbsp;</p>
        <h3 className="font-bold">11. Disclaimer of Warranties</h3>
        <p>&nbsp;</p>
        <p>
          The Service, including all plugins, is provided "as is" and "as
          available" without warranties of any kind, express or implied. This
          includes, but is not limited to, implied warranties of
          merchantability, fitness for a particular purpose, and
          non-infringement.
        </p>
        <p>&nbsp;</p>
        <h3 className="font-bold">12. Limitation of Liability</h3>
        <p>&nbsp;</p>
        <p>
          To the fullest extent permitted by law, KuriPlugins and its affiliates
          are not liable for any direct, indirect, incidental, special, or
          consequential damages arising from your use of the Service or plugins,
          including loss of data or interruption of business.
        </p>
        <p>&nbsp;</p>
        <h3 className="font-bold">13. Governing Law and Dispute Resolution</h3>
        <p>&nbsp;</p>
        <p>
          These Terms are governed by the laws of the European Union (EU)
          without regard to its conflict of law principles. Disputes shall be
          resolved through binding arbitration or small claims court, as
          appropriate, in your local jurisdiction unless otherwise required by
          applicable law.
        </p>
        <p>&nbsp;</p>
        <h3 className="font-bold">14. Severability Clause</h3>
        <p>&nbsp;</p>
        <p>
          If any provision of these Terms is found to be invalid, unenforceable,
          or in conflict with applicable law by a court of competent
          jurisdiction, that provision shall be severed, and the remaining
          provisions shall remain fully enforceable and binding.
        </p>
        <p>&nbsp;</p>
        <h3 className="font-bold">15. Changes to Terms</h3>
        <p>&nbsp;</p>
        <p>
          We may update these Terms from time to time. Changes will be posted on
          our website with the updated date. By continuing to use the Service
          after changes take effect, you agree to the updated Terms.
        </p>
        <p>&nbsp;</p>
        <h3 className="font-bold">16. Your Rights Under Consumer Law</h3>
        <p>&nbsp;</p>
        <p>
          Nothing in these Terms is intended to limit your rights under
          applicable consumer protection laws. If any provision is found invalid
          or unenforceable under such laws, the remaining provisions shall
          continue to apply.
        </p>
        <p>&nbsp;</p>
        <h3 className="font-bold">17. Contact Information</h3>
        <p>&nbsp;</p>
        <p>
          For questions, concerns, or support, please contact us at{" "}
          <a
            href="mailto:kuri.plugins@gmail.com"
            className="text-blue-500 underline"
          >
            kuri.plugins@gmail.com
          </a>
          .
        </p>
      </div>
      <button
        className="absolute right-7 top-5 h-5 w-5 cursor-pointer transition-all duration-200 hover:opacity-10"
        onClick={() => {
          termsModalRef.current?.close();
          console.log("removing event listener useEffect");
          document.removeEventListener("click", handleOutsideClick);
        }}
      >
        <img src={CloseIcon} alt="Close" />
      </button>
    </dialog>
  );
});

export default TermsModal;
