import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Cookies from "js-cookie";

export function useDiscordToken() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    const params = new URLSearchParams(hash);
    const code = params.get("access_token");
    const expire = params.get("expires_in");

    if (!code || !expire) {
      setLoading(false);
      return;
    }

    const expiresInSeconds = parseInt(expire, 10) - 3600;
    const expiresInDays = expiresInSeconds / (60 * 60 * 24);

    Cookies.set("discordToken", code, { expires: expiresInDays });
    toast.success("Authenticated successfully!", {
      className: "bg-green-800 text-white",
    });

    setLoading(false);
  }, []);

  return { loading };
}
