import React from "react";
import { Link } from "react-router-dom";
import KuriLogo from "../../assets/kuri-logo.png";
import DiscordLogoFilled from "../../assets/DiscordLogoFilled";

function Footer() {
  return (
    <footer>
      <div className="my-14 px-5 sm:px-6 md:mx-20 xl:mx-40">
        {/* Top area: Blocks */}
        <div className="grid gap-8 border-t border-gray-200 py-8 sm:grid-cols-12 md:py-12">
          {/* 1st block */}
          <div className="sm:col-span-8 lg:col-span-6">
            <div className="mb-2">
              {/* Logo */}
              <Link to="/" className="inline-block" aria-label="Kuri">
                <img src={KuriLogo} className="h-8 w-8" alt="" />
              </Link>
            </div>
            <div className="text-base text-footerLinks">
              <Link
                to="/terms-and-conditions"
                className="transition duration-150 ease-in-out hover:text-white hover:underline"
              >
                Terms
              </Link>{" "}
              ·{" "}
              <Link
                to="/privacy-policy"
                className="transition duration-150 ease-in-out hover:text-white hover:underline"
              >
                Privacy Policy
              </Link>
            </div>
          </div>

          {/* 2nd block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
            <h6 className="mb-2 font-medium text-gray-600">Kuri Plugins</h6>
            <ul className="text-base">
              <li className="mb-2">
                <Link
                  to="/"
                  className="text-footerLinks transition duration-150 ease-in-out hover:text-white"
                >
                  Home
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/store"
                  className="text-footerLinks transition duration-150 ease-in-out hover:text-white"
                >
                  Store
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/getting-started"
                  className="text-footerLinks transition duration-150 ease-in-out hover:text-white"
                >
                  Getting Started
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/faq"
                  className="text-footerLinks transition duration-150 ease-in-out hover:text-white"
                >
                  FAQ
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/about"
                  className="text-footerLinks transition duration-150 ease-in-out hover:text-white"
                >
                  About us
                </Link>
              </li>
            </ul>
          </div>

          {/* 3rd block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
            <h6 className="mb-2 font-medium text-gray-600">Resources</h6>
            <ul className="text-base">
              <li className="mb-2">
                <Link
                  to="https://www.youtube.com/@KuriPlugins"
                  className="text-footerLinks transition duration-150 ease-in-out hover:text-white"
                >
                  Tutorials & Guides
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="https://oldschool.runescape.wiki/"
                  className="text-footerLinks transition duration-150 ease-in-out hover:text-white"
                >
                  OSRS Wiki
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="https://github.com/runelite/runelite"
                  className="text-footerLinks transition duration-150 ease-in-out hover:text-white"
                >
                  Runelite Github
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="https://discord.gg/3bhXYtd8bt"
                  className="text-footerLinks transition duration-150 ease-in-out hover:text-white"
                >
                  Support
                </Link>
              </li>
            </ul>
          </div>

          {/* 4th block */}
          <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
            <h6 className="mb-2 font-medium text-gray-600">Policies</h6>
            <ul className="text-base">
              <li className="mb-2">
                <Link
                  to="/terms-and-conditions"
                  className="text-footerLinks transition duration-150 ease-in-out hover:text-white"
                >
                  Terms & Conditions
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/privacy-policy"
                  className="text-footerLinks transition duration-150 ease-in-out hover:text-white"
                >
                  Privacy
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom area */}
        <div className="border-t border-gray-200 py-4 md:flex md:items-center md:justify-between md:py-8">
          {/* Social links */}
          <ul className="mb-4 flex md:order-1 md:mb-0 md:ml-4">
            <li>
              <Link
                to="https://discord.gg/3bhXYtd8bt"
                className="hover:bg-white-100 flex items-center justify-center rounded-full text-gray-600 shadow transition duration-150 ease-in-out hover:text-white"
                aria-label="Discord"
              >
                <DiscordLogoFilled className="h-8 w-8"/>
              </Link>
            </li>
            <li className="ml-4">
              <Link
                to="https://www.youtube.com/@KuriPlugins"
                className="hover:bg-white-100 flex items-center justify-center rounded-full text-gray-600 shadow transition duration-150 ease-in-out hover:text-white"
                aria-label="Youtube"
              >
                <svg
                  viewBox="0 0 32 32"
                  className="h-8 w-8 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12.932 20.459v-8.917l7.839 4.459zM30.368 8.735c-0.354-1.301-1.354-2.307-2.625-2.663l-0.027-0.006c-3.193-0.406-6.886-0.638-10.634-0.638-0.381 0-0.761 0.002-1.14 0.007l0.058-0.001c-0.322-0.004-0.701-0.007-1.082-0.007-3.748 0-7.443 0.232-11.070 0.681l0.434-0.044c-1.297 0.363-2.297 1.368-2.644 2.643l-0.006 0.026c-0.4 2.109-0.628 4.536-0.628 7.016 0 0.088 0 0.176 0.001 0.263l-0-0.014c-0 0.074-0.001 0.162-0.001 0.25 0 2.48 0.229 4.906 0.666 7.259l-0.038-0.244c0.354 1.301 1.354 2.307 2.625 2.663l0.027 0.006c3.193 0.406 6.886 0.638 10.634 0.638 0.38 0 0.76-0.002 1.14-0.007l-0.058 0.001c0.322 0.004 0.702 0.007 1.082 0.007 3.749 0 7.443-0.232 11.070-0.681l-0.434 0.044c1.298-0.362 2.298-1.368 2.646-2.643l0.006-0.026c0.399-2.109 0.627-4.536 0.627-7.015 0-0.088-0-0.176-0.001-0.263l0 0.013c0-0.074 0.001-0.162 0.001-0.25 0-2.48-0.229-4.906-0.666-7.259l0.038 0.244z"></path>
                </svg>
              </Link>
            </li>
            <li className="ml-4">
              <Link
                to="https://github.com/KuriPlugins"
                className="hover:bg-white-100 flex items-center justify-center rounded-full text-gray-600 shadow transition duration-150 ease-in-out hover:text-white"
                aria-label="Github"
              >
                <svg
                  className="h-8 w-8 fill-current"
                  viewBox="0 0 98 96"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M48.854 0C21.839 0 0 22 0 49.217c0 21.756 13.993 40.172 33.405 46.69 2.427.49 3.316-1.059 3.316-2.362 0-1.141-.08-5.052-.08-9.127-13.59 2.934-16.42-5.867-16.42-5.867-2.184-5.704-5.42-7.17-5.42-7.17-4.448-3.015.324-3.015.324-3.015 4.934.326 7.523 5.052 7.523 5.052 4.367 7.496 11.404 5.378 14.235 4.074.404-3.178 1.699-5.378 3.074-6.6-10.839-1.141-22.243-5.378-22.243-24.283 0-5.378 1.94-9.778 5.014-13.2-.485-1.222-2.184-6.275.486-13.038 0 0 4.125-1.304 13.426 5.052a46.97 46.97 0 0 1 12.214-1.63c4.125 0 8.33.571 12.213 1.63 9.302-6.356 13.427-5.052 13.427-5.052 2.67 6.763.97 11.816.485 13.038 3.155 3.422 5.015 7.822 5.015 13.2 0 18.905-11.404 23.06-22.324 24.283 1.78 1.548 3.316 4.481 3.316 9.126 0 6.6-.08 11.897-.08 13.526 0 1.304.89 2.853 3.316 2.364 19.412-6.52 33.405-24.935 33.405-46.691C97.707 22 75.788 0 48.854 0z" />
                </svg>
              </Link>
            </li>
          </ul>

          {/* Copyrights note */}
          <div className="mr-4 text-sm text-gray-600">
            Copyright © 2025{" "}
            <a
              className="text-blue-600 hover:underline"
              href="https://github.com/KuriPlugins"
            >
              Kuri Plugins
            </a>
            . All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
