import React, { useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import { useCartContext } from "../../context/CartContext";
import {
  IdentifiedStripePrice,
  IdentifiedBundleProduct,
} from "../../services/products";
import CloseIcon from "../../assets/close-icon.png";
import ShoppingCart from "../../assets/ShoppingCart";
import DiscordAuthButton from "./DiscordAuthButton";
import { StripeService } from "../../services/products/services/StripeService";
import StripeEmbed from "../stripe-embed/StripeEmbed";
import { getDiscordToken } from "../../utils";
import DiscordLogoFilled from "../../assets/DiscordLogoFilled";
import { useNavigate } from "react-router-dom";

const CheckoutDrawer: React.FC = () => {
  const navigate = useNavigate();
  const { cart, removeFromCart, addToCart, toggleDrawer, drawerOpen } =
    useCartContext();
  const [selectedPrices, setSelectedPrices] = useState<{
    [key: string]: IdentifiedStripePrice;
  }>({});
  const [discordToken, setDiscordToken] = useState<string | undefined>(
    Cookies.get("discordToken"),
  );
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [checkingOut, setCheckingOut] = useState(false);

  const modalRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    const handleCookieChange = () => {
      const token = Cookies.get("discordToken");
      if (token !== discordToken) {
        setDiscordToken(token);
      }
    };

    // Set an interval to check for cookie changes
    const intervalId = setInterval(handleCookieChange, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [discordToken]);

  useEffect(() => {
    modalRef.current?.showModal();
  }, [clientSecret]);

  if (!drawerOpen) return null;

  const handlePriceChange = (
    productId: string,
    price: IdentifiedStripePrice,
  ) => {
    setSelectedPrices((prev) => ({ ...prev, [productId]: price }));
    const product = cart.find((item) => item.product.id === productId)?.product;
    if (product) {
      addToCart(product, price);
    }
  };

  const getTotalPrice = () => {
    return cart.reduce((total, item) => {
      const price = selectedPrices[item.product.id] || item.price;
      return total + price.price.amount;
    }, 0);
  };

  const getTotalSavings = () => {
    return cart.reduce((total, item) => {
      const price = selectedPrices[item.product.id] || item.price;
      const originalPrice = item.product.price.amount;
      return total + (originalPrice * price.period - price.price.amount);
    }, 0);
  };

  const isIdentifiedBundleProduct = (
    product: IdentifiedBundleProduct | any,
  ): product is IdentifiedBundleProduct => {
    return (product as IdentifiedBundleProduct).pluginIds !== undefined;
  };

  const handleProductPurchase = async () => {
    if (!discordToken) return;

    const requestBody = {
      discordToken,
      priceIds: cart.map((item) => item.price.id),
    };

    setCheckingOut(true);
    StripeService.initiateStripeSession(requestBody)
      .then((response) => {
        setClientSecret(response.clientSecret || "");
        modalRef.current?.showModal();
        setCheckingOut(false);
      })
      .catch((error) => {
        console.error("Failed to initiate Stripe session", error);
        setCheckingOut(false);
      });
  };

  const handleNavigateToStore = () => {
    navigate("/store");
    toggleDrawer();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-end justify-end md:items-center">
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={toggleDrawer}
      ></div>
      <div className="flex h-full w-full transform animate-slide-in-right flex-col bg-background-500 p-4 shadow-lg transition-transform duration-300 ease-in-out lg:w-1/3">
        <div className="absolute right-6 top-5 text-white">
          <img
            className="h-5 cursor-pointer transition-all duration-200 hover:opacity-10"
            src={CloseIcon}
            alt="Close"
            onClick={toggleDrawer}
          />
        </div>
        <h2 className="mb-4 text-xl font-bold text-white">Checkout</h2>
        {cart.length > 0 ? (
          <div className="no-scrollbar mb-4 flex-grow space-y-4 overflow-y-scroll">
            {cart.map((item, index) => (
              <div
                key={item.product.id}
                className="animate-slide-in-right rounded-lg bg-background-100 p-2"
              >
                {/* Container for larger screens */}
                <div className="hidden items-center sm:flex">
                  <div className="mr-10">
                    <img
                      src={item.product.imageUrl}
                      alt={item.product.name}
                      className="w-25 h-20 rounded-lg"
                    />
                  </div>
                  <div>
                    <div className="text-white">
                      {item.product.name}{" "}
                      {isIdentifiedBundleProduct(item.product) ? "Bundle" : ""}
                    </div>
                    <div className="text-gray-400">
                      {(
                        selectedPrices[item.product.id]?.price.amount ||
                        item.price.price.amount
                      ).toFixed(2)}
                      €
                    </div>
                    {item.price.period > 1 && (
                      <div className="text-green-300">
                        {(item.price.price.amount / item.price.period).toFixed(
                          2,
                        )}
                        € / Month
                      </div>
                    )}
                  </div>
                  <div className="ml-auto flex flex-col items-center justify-center space-y-2">
                    <div>
                      <select
                        className="h-10 w-28 rounded-lg border border-secondary-100 bg-background-500 text-center text-white"
                        value={
                          selectedPrices[item.product.id]?.id || item.price.id
                        }
                        onChange={(e) =>
                          handlePriceChange(
                            item.product.id,
                            item.product.prices.find(
                              (price) => price.id === e.target.value,
                            ) as IdentifiedStripePrice,
                          )
                        }
                      >
                        {item.product.prices
                          ?.sort((a, b) => a.period - b.period)
                          .map((price) => (
                            <option key={price.id} value={price.id}>
                              {price.period} Months
                            </option>
                          ))}
                      </select>
                    </div>
                    <div>
                      <span
                        className="cursor-pointer text-white"
                        onClick={() => removeFromCart(item.product.id)}
                      >
                        Remove
                      </span>
                    </div>
                  </div>
                </div>

                {/* Container for smaller screens */}
                <div className="flex flex-col sm:hidden">
                  <div className="flex items-center">
                    <div className="mr-4">
                      <img
                        src={item.product.imageUrl}
                        alt={item.product.name}
                        className="h-20 w-28"
                      />
                    </div>
                    <div className="flex flex-grow flex-col">
                      <div className="flex items-center justify-between">
                        <div className="text-white">
                          {item.product.name}{" "}
                          {isIdentifiedBundleProduct(item.product)
                            ? "Bundle"
                            : ""}
                        </div>
                        <div
                          className="cursor-pointer text-white"
                          onClick={() => removeFromCart(item.product.id)}
                        >
                          <img
                            className="h-3 transition-all duration-200 hover:opacity-10"
                            src={CloseIcon}
                            alt="Close"
                            onClick={() => removeFromCart(item.product.id)}
                          />
                        </div>
                      </div>
                      <div className="mt-2 flex items-center justify-between">
                        <div className="flex flex-col">
                          <div className="text-gray-400">
                            {(
                              selectedPrices[item.product.id]?.price.amount ||
                              item.price.price.amount
                            ).toFixed(2)}
                            €
                          </div>
                          {item.price.period > 1 && (
                            <div className="text-green-300">
                              {(
                                item.price.price.amount / item.price.period
                              ).toFixed(2)}
                              €/mo
                            </div>
                          )}
                        </div>

                        <select
                          className="h-10 w-[100px] rounded-lg border border-secondary-100 bg-background-500 text-center text-white"
                          value={
                            selectedPrices[item.product.id]?.id || item.price.id
                          }
                          onChange={(e) =>
                            handlePriceChange(
                              item.product.id,
                              item.product.prices.find(
                                (price) => price.id === e.target.value,
                              ) as IdentifiedStripePrice,
                            )
                          }
                        >
                          {item.product.prices
                            ?.sort((a, b) => a.period - b.period)
                            .map((price) => (
                              <option key={price.id} value={price.id}>
                                {price.period} Months
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="mt-10 flex h-full flex-col items-center">
            <div data-aos="slide-right" data-aos-delay="250">
              <ShoppingCart />
            </div>
            <div
              className="mb-5 text-white"
              data-aos="slide-up"
              data-aos-delay="250"
            >
              Your cart is empty. Check our store to start shopping!
            </div>
            <button
              data-aos="slide-up"
              data-aos-delay="250"
              className={`btn-gradient flex transform rounded-lg px-4 py-2 text-center font-semibold text-white shadow-lg transition-all duration-200 disabled:cursor-not-allowed disabled:opacity-50 sm:w-auto md:px-6 ${
                cart.length !== 0
                  ? "hover:scale-105 hover:bg-white active:scale-95"
                  : ""
              }`}
              onClick={handleNavigateToStore}
            >
              Store
            </button>
          </div>
        )}

        <div className="mt-auto flex items-center justify-between border-t border-white pt-4">
          <div className="flex flex-col justify-center xl:flex-row">
            <div className="flex justify-between text-green-300 xl:mr-4">
              <div className="mr-2">
                Total Savings: {getTotalSavings().toFixed(2)}€
              </div>
            </div>
            <div className="flex justify-between text-white">
              <div className="mr-2">
                Total Price: {getTotalPrice().toFixed(2)}€
              </div>
            </div>
          </div>
          <div className="items-center">
            {discordToken ? (
              <div className="flex space-x-5">
                <button
                  className="relative flex w-14 justify-center rounded-lg bg-background-100 pt-[2px] text-white transition-all duration-200 hover:scale-105"
                  onClick={getDiscordToken}
                >
                  <DiscordLogoFilled className="z-0 h-[25px] w-[25px]" />
                  <div className="absolute bottom-[-3px] left-1/2 -translate-x-1/2 text-white">
                    <span className="text-[10px]">REFRESH</span>
                  </div>
                </button>
                <button
                  className={`btn-gradient flex w-auto transform rounded-lg px-4 py-2 font-semibold text-white shadow-lg transition-all duration-200 disabled:cursor-not-allowed disabled:opacity-50 md:px-6 ${
                    cart.length !== 0
                      ? "hover:scale-105 hover:bg-white active:scale-95"
                      : ""
                  }`}
                  onClick={() => handleProductPurchase()}
                  disabled={cart.length === 0 || checkingOut}
                >
                  Checkout
                </button>
              </div>
            ) : (
              <DiscordAuthButton />
            )}
          </div>
        </div>
      </div>
      {clientSecret && (
        <StripeEmbed options={{ clientSecret }} ref={modalRef} />
      )}
    </div>
  );
};

export default CheckoutDrawer;
