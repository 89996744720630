import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDropdownContext } from "../../context/DropdownContext";
import { useCartContext } from "../../context/CartContext";
import Spinner from "../../assets/Spinner";
import { IdentifiedStripePrice } from "../../services/products";
import { getPeriodString } from "../../utils";

const GlobalDropdown: React.FC = () => {
  const { product, prices, isVisible, position, hideDropdown } =
    useDropdownContext();
  const { addToCart } = useCartContext();
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        hideDropdown();
      }
    };

    if (isVisible) {
      setTimeout(() => {
        document.addEventListener("click", handleClickOutside);
      }, 0);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isVisible]);

  const sortedPrices = prices
    ? [...prices].sort((a, b) => a.period - b.period)
    : [];

  const handlePriceClick = (price: IdentifiedStripePrice) => {
    if (product) {
      addToCart(product, price);
      hideDropdown();
    }
  };

  const handlePriceClickWithNotification = (price: IdentifiedStripePrice) => {
    handlePriceClick(price);
    toast.success(
      `${getPeriodString(price)} of ${product?.name} added to the cart!`,
      { className: "bg-green-800 text-white" },
    );
  };

  return (
    <>
      {isVisible && (
        <div
          ref={dropdownRef}
          className="absolute z-10 w-[200px] animate-modal-slide-in rounded-lg border border-black bg-background-500 shadow-lg"
          style={{
            top: position.y,
            left: position.x,
          }}
        >
          <div className="p-2">
            {sortedPrices.length > 0 ? (
              sortedPrices.map((price, index) => (
                <div
                  key={index}
                  className="cursor-pointer rounded-lg p-2 hover:bg-background-100"
                  onClick={() => handlePriceClickWithNotification(price)}
                >
                  <p className="text-white">{getPeriodString(price)}</p>
                  <p className="text-gray-500">
                    {(price.price.amount / price.period).toFixed(2)}€ / Month
                  </p>
                </div>
              ))
            ) : (
              <div className="flex justify-center">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default GlobalDropdown;
