/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InitiateStripeSessionRequest } from '../models/InitiateStripeSessionRequest';
import type { InitiateStripeSessionResponse } from '../models/InitiateStripeSessionResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class StripeService {
    /**
     * Initiate a Stripe session
     * @param requestBody
     * @returns InitiateStripeSessionResponse Stripe session successfully created
     * @throws ApiError
     */
    public static initiateStripeSession(
        requestBody: InitiateStripeSessionRequest,
    ): CancelablePromise<InitiateStripeSessionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/web-api/v1/stripe/session',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input (e.g., invalid or missing Discord token, empty priceIds)`,
                500: `Internal server error`,
            },
        });
    }
}
