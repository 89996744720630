import React, { useEffect } from 'react';
import { useDiscordToken } from '../../hooks/useDiscordToken';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import KuriLogo from "../../assets/kuri-logo.png";

const AuthSuccess = () => {
    const { loading } = useDiscordToken();

    useEffect(() => {
        if (!loading) {
            window.close();
        }
    }, [loading]);

    return (
        <div className="flex min-h-screen flex-col overflow-hidden">
            {/* Site header */}
            <Header />

            <main className="flex flex-grow flex-col items-center px-5 pt-10 text-center xl:mx-96 xl:px-32 xl:pt-40">
                <div className="container mx-auto flex flex-col items-center">
                    <img src={KuriLogo} alt="Kuri Logo" className="mb-6" />
                    <p className="text-xl font-normal text-secondary-200">
                        Discord authenticated!
                    </p>
                </div>
            </main>

            {/* Site footer */}
            <Footer />
        </div>
    );
};

export default AuthSuccess;