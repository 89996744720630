// GlobalContext.tsx
import React, { createContext, useContext, useState } from "react";
import {
  IdentifiedBundleProduct,
  IdentifiedPluginProduct,
} from "../services/products";

interface GlobalContextProps {
  selectedProduct: IdentifiedBundleProduct | IdentifiedPluginProduct | null;
  setSelectedProduct: (
    product: IdentifiedBundleProduct | IdentifiedPluginProduct | null,
  ) => void;

  bundles: IdentifiedBundleProduct[];
  setBundles: (bundles: IdentifiedBundleProduct[]) => void;
  plugins: IdentifiedPluginProduct[];
  setPlugins: (plugins: IdentifiedPluginProduct[]) => void;
}

const GlobalContext = createContext<GlobalContextProps | undefined>(undefined);

export const GlobalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [selectedProduct, setSelectedProduct] = useState<
    IdentifiedBundleProduct | IdentifiedPluginProduct | null
  >(null);

  const [bundles, setBundles] = useState<IdentifiedBundleProduct[]>([]);
  const [plugins, setPlugins] = useState<IdentifiedPluginProduct[]>([]);

  return (
    <GlobalContext.Provider
      value={{
        selectedProduct,
        setSelectedProduct,
        bundles,
        setBundles,
        plugins,
        setPlugins,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error("useGlobalContext must be used within a GlobalProvider");
  }
  return context;
};
