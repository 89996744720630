/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BundleProductsGet } from '../models/BundleProductsGet';
import type { IdentifiedStripeProduct } from '../models/IdentifiedStripeProduct';
import type { PluginProductsGet } from '../models/PluginProductsGet';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ProductService {
    /**
     * Get list of sellix bundle products
     * @returns BundleProductsGet OK
     * @throws ApiError
     */
    public static getBundleProducts(): CancelablePromise<BundleProductsGet> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web-api/v1/products/bundles',
        });
    }
    /**
     * Get list of plugin products
     * @returns PluginProductsGet OK
     * @throws ApiError
     */
    public static getPluginProducts(): CancelablePromise<PluginProductsGet> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web-api/v1/products/plugins',
        });
    }
    /**
     * Get a Stripe product by its ID
     * @param id Unique identification of the Stripe product
     * @returns IdentifiedStripeProduct OK
     * @throws ApiError
     */
    public static getStripeProductById(
        id: string,
    ): CancelablePromise<IdentifiedStripeProduct> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/web-api/v1/products/stripe/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Stripe product not found`,
            },
        });
    }
}
