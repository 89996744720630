import React, { createContext, useContext, useState, useRef } from "react";
import {
  IdentifiedStripePrice,
  IdentifiedBundleProduct,
  IdentifiedPluginProduct,
  ProductService,
} from "../services/products";

interface DropdownContextProps {
  product: IdentifiedBundleProduct | IdentifiedPluginProduct | null;
  prices: IdentifiedStripePrice[] | null;
  isVisible: boolean;
  position: { x: number; y: number };
  showDropdown: (
    product: IdentifiedBundleProduct | IdentifiedPluginProduct,
    position: { x: number; y: number },
  ) => void;
  hideDropdown: () => void;
}

const DropdownContext = createContext<DropdownContextProps | undefined>(
  undefined,
);

export const DropdownProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [product, setProduct] = useState<
    IdentifiedBundleProduct | IdentifiedPluginProduct | null
  >(null);
  const [prices, setPrices] = useState<IdentifiedStripePrice[] | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const priceCache = useRef<{ [key: string]: IdentifiedStripePrice[] }>({});

  const showDropdown = async (
    newProduct: IdentifiedBundleProduct | IdentifiedPluginProduct,
    newPosition: { x: number; y: number },
  ) => {
    setIsVisible(true);
    setPosition(newPosition);

    if (newProduct.id !== product?.id) {
      setProduct(newProduct);
      setPrices(null);
      if (priceCache.current[newProduct.id]) {
        setPrices(priceCache.current[newProduct.id]);
      } else {
        try {
          if (newProduct.stripeProductId) {
            const pricesData = await ProductService.getStripeProductById(
              newProduct.stripeProductId,
            );
            newProduct.prices = pricesData.prices;
            setPrices(pricesData.prices);
            priceCache.current[newProduct.id] = pricesData.prices;
          } else {
            console.error("Error: stripeProductId is undefined");
          }
        } catch (error) {
          console.error("Error fetching prices:", error);
        }
      }
    }
  };

  const hideDropdown = () => {
    setIsVisible(false);
  };

  return (
    <DropdownContext.Provider
      value={{
        product,
        prices,
        isVisible,
        position,
        showDropdown,
        hideDropdown,
      }}
    >
      {children}
    </DropdownContext.Provider>
  );
};

export const useDropdownContext = () => {
  const context = useContext(DropdownContext);
  if (!context) {
    throw new Error(
      "useDropdownContext must be used within a DropdownProvider",
    );
  }
  return context;
};
